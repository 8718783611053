import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "30",
  height: "28",
  viewBox: "0 0 30 28",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#FF7602",
      d: "M0.919922 11.6016L2.67771 27.497H20.6734V13.376L17.1379 9.84049C15.2171 7.91979 14.1377 5.31414 14.1377 2.59767V0.50296H8.82219V4.66467C8.82219 7.08855 9.78531 9.41268 11.4988 11.1261L11.9742 11.6016H0.919922Z"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#212529",
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M22.25 27.497H26.9492L29.0805 12.1281H22.25V27.497ZM25.3326 17.4153C26.2757 17.4153 27.0403 16.6508 27.0403 15.7077C27.0403 14.7646 26.2757 14 25.3326 14C24.3895 14 23.625 14.7646 23.625 15.7077C23.625 16.6508 24.3895 17.4153 25.3326 17.4153Z"
    }, null, -1)
  ])))
}
export default { render: render }